//
// Internal class that helps "asynchronous WebGL query objects" manage
// pending requests (e.g. for EXT_disjoint_timer_query and WebGL2 queries)
//
// Creates and manages promises for the queries.
// Tracks pending queries enabling polling.
// Tracks pending queries enabling invalidation.
// Provides some standard error messages.
//
// Remarks:
// - Maintains a minimal list of pending queries only to minimize GC impact
// - Exported as a singleton class instance.

const ERR_DELETED = 'Query was deleted before result was available';
const ERR_CANCEL = 'Query was canceled before result was available';

const noop = x => x;

class QueryManager {
  constructor() {
    this.pendingQueries = new Set();
    this.invalidQueryType = null;
    this.invalidErrorMessage = '';
    this.checkInvalid = () => false;
  }

  // API THAT SHOULD BE EXPOSED TO APPLICATION

  // Checks invalidation callback and then all pending queries for completion
  // Should only be called once per tick
  poll(gl) {
    this.cancelInvalidQueries(gl);

    // Now check availability of results and resolve promises as appropriate
    for (const query of this.pendingQueries.values()) {
      const resultAvailable = query.isResultAvailable();
      if (resultAvailable) {
        const result = query.getResult();
        this.resolveQuery(query, result);
      }
    }
  }

  // API FOR MANAGED QUERY CLASSES

  // Registers query invalidation method - used to detect disjoint timer queries
  setInvalidator({queryType, errorMessage, checkInvalid}) {
    this.invalidQueryType = queryType;
    this.invalidErrorMessage = errorMessage;
    this.checkInvalid = checkInvalid;
  }

  // Starts a query, sets up a new promise
  beginQuery(query, onComplete = noop, onError = noop) {
    // Make sure disjoint state is cleared, so that this query starts fresh
    // Cancel other queries if needed
    this.cancelInvalidQueries(query.gl);

    // Cancel current promise - noop if already resolved or rejected
    this.cancelQuery(query);

    // Create a new promise with attached resolve and reject methods
    const resolvers = {};
    query.promise = new Promise((resolve, reject) => {
      resolvers.resolve = resolve;
      resolvers.reject = reject;
    });
    Object.assign(query.promise, resolvers);

    // Add this query to the pending queries
    this.pendingQueries.add(query);
    // Register the callbacks
    return query.promise.then(onComplete).catch(onError);
  }

  // Resolves a query with a result
  resolveQuery(query, result) {
    this.pendingQueries.delete(query);
    query.promise.resolve(result);
  }

  // Rejects the promise
  rejectQuery(query, errorMessage) {
    this.pendingQueries.delete(query);
    if (query.promise) {
      query.promise.reject(new Error(errorMessage));
    }
  }

  // Rejects promise with standard message for Query.delete()
  deleteQuery(query) {
    return this.rejectQuery(query, ERR_DELETED);
  }

  // Rejects promise with standard message for Query.cancel()
  cancelQuery(query) {
    return this.rejectQuery(query, ERR_CANCEL);
  }

  // Rejects promise with registered message for invalidation
  invalidateQuery(query) {
    if (query instanceof this.invalidQueryType) {
      this.rejectQuery(query, this.invalidErrorMessage);
    }
  }

  // Checks all queries to see if need to be invalidated
  cancelInvalidQueries(gl) {
    // We assume that we can cancel queries for all context.
    // Should be OK since this is used to check for "disjoint" GPU state
    if (this.checkInvalid(gl)) {
      for (const query of this.pendingQueries.values()) {
        this.invalidateQuery(query);
      }
    }
  }
}

export default new QueryManager();
